<template>
    <div>
        <div class="title">{{ tableUserColumnData.groupName }}</div>
        <el-checkbox-group v-model="newCheckList" style="margin: 20px 0 0 30px">
            <el-checkbox style="margin-bottom: 20px" v-for="item in tableUserColumnData.userColumn"
                :key="item.user_mx_id" :label="item.user_mx_id" @change="changeList">{{ item.realname }}</el-checkbox>
        </el-checkbox-group>
        <div class="footer-btn">
            <el-button type="primary" size="small" @click="$emit('close', false)">取消</el-button>
            <el-button type="primary" size="small" @click="handleDetermineBtn">确定</el-button>
        </div>
    </div>
</template>

<script>
import { authManageTableColumnUpdateAction } from '@/common/api/setting/authManage.js';
export default {
    name: 'tableUserColumn',
    props: {
        tableUserColumnData: {
            type: Object,
            default: () => { }
        }
    },
    data() {
        return {
            // chekbox回显数据值
            oldCheckList: [],
            // chekbox选中值
            newCheckList: [],
            // checkbox未选中的值
            checkList: []
        };
    },
    mounted() {
        this.newCheckList = this.tableUserColumnData.userColumn.map(item => {
            return item.user_mx_id;
        });
        this.oldCheckList = this.tableUserColumnData.userColumn.map(item => {
            return item.user_mx_id;
        });
    },
    methods: {
        changeList() {
            this.checkList = this.oldCheckList.filter(item => !this.newCheckList.some(ele => ele === item));
        },
        handleDetermineBtn() {
            let params = {
                user_mx_id: this.checkList,
                role_mx_id: this.tableUserColumnData.groupId
            };
            authManageTableColumnUpdateAction(params)
                .then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: `${res.msg}`,
                            center: true
                        });
                        this.$emit('close', false);
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        }
    },
    watch: {
        tableUserColumnData: function (newVal) {
            this.newCheckList = newVal.userColumn.map(item => {
                return item.user_mx_id;
            });
            this.oldCheckList = newVal.userColumn.map(item => {
                return item.user_mx_id;
            });
        }
    }
};
</script>

<style scoped>
.title {
    margin: 20px 0 0 30px;
}

:deep(.el-checkbox),
:deep(.el-checkbox__input.is-checked + .el-checkbox__label) {
    color: #000 !important;
}

.footer-btn {
    margin-top: 50px;
    text-align: center;
}
</style>