<template>
    <div>
        <el-form size="small" :model="ruleForm" :rules="rules" ref="ruleForm" label-width="120px">
            <el-form-item label="分组名称:" prop="groupName">
                <el-input style="width: 200px" v-model="ruleForm.groupName"></el-input>
            </el-form-item>
            <el-form-item label="分组说明:" prop="groupInstructions">
                <el-input type="textarea" :autosize="{ minRows: 2 }" style="width: 200px"
                    v-model="ruleForm.groupInstructions"></el-input>
            </el-form-item>
            <el-form-item style="margin-top: 35px">
                <el-button style="width: 100px" type="primary" @click="handleSave">确认</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>

<script>
import { AuthManageAddGroupAction, AuthManageEditGroupAction } from '@/common/api/setting/authManage.js';
export default {
    name: 'authManageAddEdit',
    props: {
        type: {
            type: String,
            defalut: ''
        },
        authManageAddEditData: {
            type: Object,
            defalut: () => { }
        }
    },
    data() {
        // var validateGroupName = (rule, value, callback) => {
        //     if (!value) {
        //         return callback(new Error('分组名称不能为空'));
        //     } else {
        //         callback();
        //     }
        // };
        // var validateGroupInstructions = (rule, value, callback) => {
        //     if (!value) {
        //         return callback(new Error('分组说明不能为空'));
        //     } else {
        //         callback();
        //     }
        // };
        return {
            ruleForm: {
                // 分组id
                groupId: '',
                // 分组名称
                groupName: '',
                // 分组说明
                groupInstructions: ''
            },
            rules: {
                groupName: [{ required: true, message: '分组名称不能为空', trigger: 'blur' }],
                groupInstructions: [
                    // { pattern: /^[\u4E00-\u9FA5A-Za-z]+$/, message: '只能输入中文和英文字母', trigger: 'blur' }
                ]
            }
        };
    },
    mounted() {
        if (this.type == 'edit') {
            this.ruleForm = this.authManageAddEditData;
        }
    },
    methods: {
        handleSave() {
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    let addParams = {
                        name: this.ruleForm.groupName,
                        describe: this.ruleForm.groupInstructions
                    };
                    let editParams = {
                        ...addParams,
                        id: this.ruleForm.groupId
                    };
                    let params = this.type == 'edit' ? editParams : addParams;
                    let submitFunc = this.type == 'edit' ? AuthManageEditGroupAction : AuthManageAddGroupAction;
                    submitFunc(params)
                        .then(res => {
                            let { code, msg } = res;
                            if (code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: `${msg}`,
                                    center: true
                                });
                                this.$emit('close', false);
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                } else {
                    // console.log('error submit!!');
                    return false;
                }
            });
        }
    },
    // computed: {
    //     ruleForm(){
    //         return this.authManageAddEditData;
    //     }
    // }
    watch: {
        authManageAddEditData: function (newVal) {
            this.ruleForm = newVal;
        }
    }
};
</script>

<style lang="scss" scoped>

</style>