<template>
    <div>
        <el-row style="padding: 25px">
            <authManagePage v-if="showPage == 'authManage'" @switchConfigPage="switchConfigPage"></authManagePage>
            <authConfigPage v-if="showPage == 'authConfig'" @goBack="goBack" :authConfigPageData="authConfigPageData"></authConfigPage>
        </el-row>
    </div>
</template>

<script>
import authManagePage from './authManagePage.vue';
import authConfigPage from './authConfigPage.vue';
export default {
    components: {
        authManagePage,
        authConfigPage
    },
    data() {
        return {
            showPage: 'authManage',
            // 权限配置页面数据
            authConfigPageData: {}
        };
    },
    methods: {
        switchConfigPage(row) {
            this.showPage = 'authConfig';
            this.authConfigPageData = row;
            // console.log('this.authConfigPageData: ', this.authConfigPageData);
        },
        goBack() {
            this.showPage = 'authManage';
        }
    }
};
</script>

<style lang="scss" scoped>
</style>