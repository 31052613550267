import $http from '../../../utils/http.js';

/**
 * 权限管理列表
 * @param data
 */
export function getAuthManageList(data) {
    return $http.get('/Auth/grouping_index', data, 'loadingDiv');
}

/**
 * 权限管理列表更新用户列操作
 * @param data
 */
export function authManageTableColumnUpdateAction(data) {
    return $http.post('/Auth/user_list_update', data, 'loadingDiv');
}

/**
 * 权限管理列表复制分组操作
 * @param data
 */
export function AuthManageCopyGroupAction(data) {
    return $http.post('/Auth/copy_grouping', data, 'loadingDiv');
}

/**
 * 权限管理列表删除操作
 * @param data
 */
export function AuthManageDeteleAction(data) {
    return $http.post('/Auth/del_grouping', data, 'loadingDiv');
}

/**
 * 权限管理添加分组
 * @param data
 */
export function AuthManageAddGroupAction(data) {
    return $http.post('/Auth/add_grouping', data, 'loadingDiv');
}

/**
 * 权限管理编辑分组
 * @param data
 */
export function AuthManageEditGroupAction(data) {
    return $http.post('/Auth/edit_grouping', data, 'loadingDiv');
}
