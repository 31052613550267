<template>
    <div class="auth-config-page" style="width: 100%">
        <div style="width: 100%; margin-bottom: 150px">
            <header class="title-wrap clearfix">
                <div class="back-btn">
                    <el-button size="small" @click="goBack">返回</el-button>
                </div>
                <span class="title-text">所有模块</span>
            </header>
            <!-- 树形结构 -->
            <div class="repair-order-modules">
                <el-tree ref="tree" :data="authConfigForm" show-checkbox default-expand-all :indent="24" node-key="id"
                    highlight-current :props="defaultProps" :expand-on-click-node="false">
                    <template v-slot="{ node, data }">
                        <div :class="{ 'need-float': data.level === 3 || data.level === 2 }">
                            {{ node.label }}
                        </div>
                    </template>
                </el-tree>
            </div>
            <footer class="footer-wrap">
                <div class="btn-wrap">
                    <el-button size="medium" type="primary" @click="handleAuthConfigSaveBtn">确定</el-button>
                    <el-button size="medium" @click="goBack">取消</el-button>
                </div>
            </footer>
        </div>
    </div>
</template>

<script>
import { getAuthConfigList, getCurrentUserAuth, modifyUserAuthAction } from '@/common/api/setting/authConfig.js';
import jsonTool from '@/utils/jsonTool.js';
export default {
    name: 'authConfigPage',
    props: {
        authConfigPageData: {
            type: Object,
            defalut: () => { }
        }
    },
    data() {
        return {
            defaultProps: {
                children: 'child',
                label: 'name'
            },
            // 所有权限数据
            authConfigForm: []
            // 分组id
            // roleId: ''
            // 回显数据
            // echoCheckedKeys: [],
            // tree选中的值
            // checkedKeys: []
        };
    },
    computed: {
        roleId() {
            return this.authConfigPageData.groupId;
        }
    },
    async mounted() {
        this.queryAuthConfigData();
        this.queryCurrentRoleAuthData();
    },
    methods: {
        async queryAuthConfigData() {
            getAuthConfigList()
                .then(res => {
                    let { code, result } = res;
                    if (code == 200) {
                        this.authConfigForm = jsonTool(result);
                        // console.log('权限配置所有配置数据：', this.authConfigForm);
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        async queryCurrentRoleAuthData() {
            let params = {
                role_mx_id: this.roleId
            };
            getCurrentUserAuth(params)
                .then(res => {
                    let { code, result } = res;
                    if (code == 200) {
                        setTimeout(() => {
                            result.forEach(id => {
                                let node = this.$refs.tree.getNode(id);
                                // console.log('node: ', node.isLeaf);
                                if (node.isLeaf) {
                                    this.$refs.tree.setChecked(id, true);
                                }
                            });
                        }, 100);
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // tree树形选中值
        // handleNodeClick(data, checkedObj) {
        //     console.log(data);
        //     console.log(checkedObj);
        //     console.log('checkedNodes: ', checkedObj.checkedNodes);
        //     console.log('checkedKeys: ', checkedObj.checkedKeys);
        // },
        // 处理取消/返回按钮
        goBack() {
            this.$emit('goBack');
        },
        // 处理确定按钮
        handleAuthConfigSaveBtn() {
            let getCheckedKeys = this.$refs.tree.getCheckedKeys();
            let getHalfCheckedKeys = this.$refs.tree.getHalfCheckedKeys();
            let checkKeys = [...getHalfCheckedKeys, ...getCheckedKeys];
            let params = {
                role_mx_id: this.roleId,
                auth_mx_id: checkKeys
            };
            modifyUserAuthAction(params)
                .then(res => {
                    if (res.code == 200) {
                        this.$message({
                            type: 'success',
                            message: `${res.msg}`,
                            center: true
                        });
                        this.$emit('goBack');
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        }
    }
};
</script>

<style scoped>
:deep(.el-tree-node__expand-icon.expanded) {
    display: none !important;
}

:deep(.el-tree-node__content) {
    width: 240px;
}

:deep(.el-tree.el-tree--highlight-current > .el-tree-node) {
    margin-bottom: 30px;
}

:deep(.el-tree) {
    padding: 20px 0 20px 30px;
}

.need-float {
    float: left;
}

.clearfix::after {
    clear: both;
    display: block;
    content: '';
    height: 0;
    visibility: collapse;
}

.title-wrap {
    height: 56px;
    text-align: center;
    border-bottom: 1px solid var(--el-border-color-base);
}

.back-btn {
    float: left;
}

.title-text {
    font-weight: 500;
    font-size: 28px;
    color: #4d4d4d;
}

.footer-wrap {
    position: fixed;
    /* left: 50%; */
    bottom: 24px;
    width: 90%;
    /* margin-left: 5.5%; */
    background-color: #fff;
    line-height: 90px;
    text-align: center;
    /* transform: translateX(-50%); */
    z-index: 9999;
}

.footer-wrap>.btn-wrap {
    margin: 0 auto;
}

.footer-wrap :deep(.el-button) {
    margin-right: 30px;
}
</style>