import $http from '../../../utils/http.js';

/**
 * 权限配置所有配置数据
 * @param data
 */
export function getAuthConfigList(data) {
    return $http.get('/Auth/auth_list', data, 'loadingDiv');
}

/**
 * 权限配置当前用户权限
 * @param data
 */
export function getCurrentUserAuth(data) {
    return $http.get('/Auth/get_auth', data, 'loadingDiv');
}

/**
 * 权限配置修改用户权限
 * @param data
 */
 export function modifyUserAuthAction(data) {
    return $http.post('/Auth/auth_update', data, 'loadingDiv');
}
