<template>
    <div>
        <el-row style="padding: 25px">
            <div style="width: 100%">
                <el-form size="small" :inline="true" :model="formSearch" class="demo-form-inline">
                    <el-form-item label="名称:">
                        <el-input style="width: 200px" v-model="formSearch.searchVal" placeholder=" "></el-input>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="handleSearch">查询</el-button>
                    </el-form-item>
                    <el-form-item style="float: right; margin-right: 0">
                        <el-button style="width: 100px" type="primary" :disabled="!permissionsAction.add"
                            @click="handleAddEditGroup('add')">添加分组</el-button>
                    </el-form-item>
                </el-form>
            </div>
            <div style="width: 100%">
                <el-table :header-cell-style="{ background: '#F2F2F2' }" :data="authManageDataList" border
                    align="center" style="width: 100%">
                    <el-table-column label="序号" width="90px" type="index" :index="indexMethod" align="center">
                    </el-table-column>
                    <el-table-column prop="groupName" label="分组名称" align="center"></el-table-column>
                    <el-table-column prop="groupInstructions" label="分组说明" align="center"></el-table-column>
                    <el-table-column prop="userColumn" label="用户列" align="center">
                        <template v-slot="scope">
                            <el-button type="text" style="cursor: pointer; white-space: pre-wrap; word-break: keep-all"
                                :disabled="!permissionsAction.update" @click="handleTableUserColumn(scope.row)">
                                {{ userColumnName(scope.row) }}
                            </el-button>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" align="center">
                        <template v-slot="scope">
                            <el-button :disabled="!permissionsAction.edit"
                                @click="handleAddEditGroup('edit', scope.row)" type="text" size="small">编辑</el-button>
                            <el-button :disabled="!permissionsAction.auth" @click="handleAuthConfigBtn(scope.row)"
                                type="text" size="small">权限</el-button>
                            <el-button :disabled="!permissionsAction.copy" @click="handleCopyGroupBtn(scope.row)"
                                type="text" size="small">复制分组</el-button>
                            <el-button :disabled="!permissionsAction.del" @click="handleDeleteBtn(scope.row)"
                                type="text" size="small">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div style="text-align: right; margin-top: 25px" v-if="page.total != 0">
                    <el-pagination background @size-change="handleSizeChange" @current-change="handleCurrentChange"
                        :current-page="page.page" :page-sizes="[10, 15, 30, 35]" :page-size="page.pageSize"
                        layout="total, sizes, prev, pager, next, jumper" :total="page.total">
                    </el-pagination>
                </div>
            </div>
        </el-row>

        <!-- 添加/编辑分组弹窗 -->
        <dialogFrame :show="dialogFormAddEditVisible" @showDialog="showDialog" :title="type == 'add' ? '添加分组' : '编辑分组'"
            :footer="false" width="450px" center>
            <authManageAddEdit :type="type" :authManageAddEditData="authManageAddEditData" @close="closeDialog"
                v-if="dialogFormAddEditVisible"></authManageAddEdit>
        </dialogFrame>

        <!-- 表格用户列弹窗 -->
        <dialogFrame :show="dialogTableUserColumnVisible" @showDialog="showDialog" :title="'用户列'" :footer="false"
            width="750px">
            <tableUserColumn :tableUserColumnData="tableUserColumnData" @close="closeDialog"></tableUserColumn>
        </dialogFrame>
    </div>
</template>

<script>
import dialogFrame from '@/components/common/dialog/dialog-frame.vue';
import authManageAddEdit from '../../component/setting/authManage/authManageAddEdit.vue';
import tableUserColumn from '../../component/setting/authManage/tableUserColumn.vue';
// import jsonTool from '@/utils/jsonTool.js';
import { get as getStorage, set as setStrorage } from '@/utils/storage.js';
import {
    getAuthManageList,
    AuthManageCopyGroupAction,
    AuthManageDeteleAction
} from '@/common/api/setting/authManage.js';
export default {
    name: 'authManagePage',
    components: {
        dialogFrame,
        authManageAddEdit,
        tableUserColumn
    },
    data() {
        return {
            // 查询合集
            formSearch: {
                // 查询名称
                searchVal: ''
            },
            // 表格数据
            authManageDataList: [],
            // 权限操作集合
            permissionsAction: {},
            page: {
                page: getStorage('currentPage', true) || 1,
                pageSize: 10,
                total: 0
            },
            // 是否显示添加/编辑分组弹窗
            dialogFormAddEditVisible: false,
            // 是否显示表格用户列弹窗
            dialogTableUserColumnVisible: false,
            // 添加/编辑类型
            type: '',
            // 添加/编辑弹窗数据
            authManageAddEditData: {},
            // 表格用户列数据
            tableUserColumnData: {}
        };
    },
    mounted() {
        this.queryAuthManageData();
    },
    methods: {
        showDialog(val) {
            this.dialogFormAddEditVisible = val;
            this.dialogTableUserColumnVisible = val;
        },
        closeDialog(val) {
            this.dialogFormAddEditVisible = val;
            this.dialogTableUserColumnVisible = val;
            this.queryAuthManageData();
        },
        userColumnName(row) {
            let nameList = [];
            if (row.userColumn) {
                row.userColumn.forEach(item => {
                    return nameList.push(item.realname);
                });
            }
            return nameList ? nameList.join(', ') : '';
        },
        // 查询权限管理数据
        queryAuthManageData() {
            let params = {
                search: this.formSearch.searchVal,
                page: this.page.page,
                pageSize: this.page.pageSize
            };
            getAuthManageList(params)
                .then(res => {
                    let { code, result, count, Power } = res;
                    if (code == 200) {
                        this.authManageDataList = result.map((item, index) => {
                            return {
                                // 分组id
                                groupId: item.id,
                                // 分组名称
                                groupName: item.name,
                                // 分组说明
                                groupInstructions: item.describe,
                                // 用户列
                                userColumn: item.child
                            };
                        });
                        let { Add: add, Auth: auth, Copy: copy, Delete: del, Edit: edit, Update: update } = Power;
                        this.permissionsAction = {
                            add,
                            auth,
                            copy,
                            del,
                            edit,
                            update
                        };
                        this.page.total = count;
                    }
                })
                .catch(err => {
                    // console.log(err);
                });
        },
        // 处理查询
        handleSearch() {
            this.queryAuthManageData();
        },
        // 处理表格用户列
        handleTableUserColumn(row) {
            this.tableUserColumnData = row;
            this.dialogTableUserColumnVisible = true;
        },
        // 处理添加/编辑分组
        handleAddEditGroup(type, row) {
            this.type = type;
            if (type == 'edit') {
                let { groupId, groupName, groupInstructions } = row;
                this.authManageAddEditData = {
                    groupId,
                    groupName,
                    groupInstructions
                };
            }
            this.dialogFormAddEditVisible = true;
        },
        // 处理权限按钮
        handleAuthConfigBtn(row) {
            this.$emit('switchConfigPage', row);
        },
        // 处理复制分组按钮
        handleCopyGroupBtn(row) {
            let params = {
                id: row.groupId
            };
            this.$confirm('确认复制当前分组吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    AuthManageCopyGroupAction(params)
                        .then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '复制成功!',
                                    center: true
                                });
                                this.queryAuthManageData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 处理删除按钮
        handleDeleteBtn(row) {
            let params = {
                id: row.groupId
            };
            this.$confirm('确认删除当前分组吗？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
                center: true
            })
                .then(() => {
                    AuthManageDeteleAction(params)
                        .then(res => {
                            if (res.code == 200) {
                                this.$message({
                                    type: 'success',
                                    message: '删除成功!',
                                    center: true
                                });
                                this.queryAuthManageData();
                            }
                        })
                        .catch(err => {
                            // console.log(err);
                        });
                })
                .catch(() => {
                    this.$message({
                        type: 'success',
                        message: '已取消操作!',
                        center: true
                    });
                });
        },
        // 下标序号
        indexMethod(index) {
            return this.page.pageSize * (this.page.page - 1) + 1 + index;
        },
        // 分页处理
        handleSizeChange(size) {
            this.page.pageSize = size;
            this.queryAuthManageData();
        },
        handleCurrentChange(page) {
            this.page.page = page;
            // 分页当前页存储到sessionStorage
            setStrorage('currentPage', this.page.page, true);
            this.queryAuthManageData();
        }
    }
};
</script>

<style lang="scss" scoped>

</style>